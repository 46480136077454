export default {
  data: function () {
    return {};
  },
  props: {
    list: {
      type: [Array, Boolean],
      default: false,
    },
  },
  computed: {
    crumbs() {
      if (this.list) return this.list;

      let route = this.$router.currentRoute._value.fullPath;
      let parts = route.split("/");
      let crumbs = [];
      let tempPath = "";
      let count = 0;
      parts.forEach((part) => {
        count++;
        if (part === "") {
          return true;
        }
        tempPath = tempPath + "/" + part;
        // todo: breadcrumbs translation
        crumbs.push({
          path: tempPath,
          name: part,
          label: utilities.ucFirst(part),
          isLast: count >= parts.length,
        });
      });

      return crumbs;
    },
  },
  created() {
    let route = this.$router.currentRoute._value.fullPath;
    //TODO: use useRoute composition instead
  },
};
